import React from "react"
import Layout from "../components/layout"
import { Link } from "gatsby"

export default () => (
  <Layout title="For Schools">
    <h2>For Schools</h2>
    <div className="clear-block">
      <div id="node-8" className="node">


        <div className="content clear-block">
          <p> In a classroom situation, keeping the child’s attention, and giving them something they can
            successfully accomplish while you are busy helping other children is vitally important for any
            teacher. Introducing music to children, who have not had any music experience before entering
            your classroom, is a great challenge. Learning to read standard music notation is very
            frustrating to some children, and they become the trouble makers in the class because they
            cannot do the assigned task.</p>
          <p>The Animal Note method makes beginning note reading not only easier, but fun. The Animal Notes
            are simple to read on the Grand Staff and to then find the correct key for that note on the
            keyboard. The word clue for each notes location helps the student not only learn the Animal
            Note, but to transfer that knowledge to standard music notation. The pieces in the “<Link
              to="/books/Beginning-Note-Reading">Note Reading for Children</Link>” teach the first nine notes
            normally learned by beginners in piano. The “<Link to="/books/Beginning-Timing">Timing for
              Children</Link>” stays with the Animal Notes so the student can concentrate on learning and
            hearing the difference in the proportions of the notes and how these notes should sound
            together. It is an opportunity to help the student develop a strong audio skill for correct
            timing in future pieces of music they will be playing. Yes, counting is a vital part of practice
            in learning the pieces in the timing book and playing them correctly.</p>
          <p>Hand position at the keyboard is very important to successful mastery of the piano piece. Both
            these challenges can lead to tension and frustration in a keyboard classroom. The “Magic Spider”
            who always walks on the tips of its legs is a great way to show the students how to use their
            fingers correctly at the keyboard, and helps them to learn correct hand position. An explanation
            of why this is so important and a demonstration of the differences it makes help the students
            develop good technique from the very beginning. </p>
          <p>The "<Link
            to="/flash-cards/Classroom-Size-Animal-Note-Standard-Note-Flash-Card-Set-Grand-Staff">Classroom Size Animal Note/standard note Grand Staff Flash Card Set</Link>"
            makes it easy htmlFor the class to study the word clues and become comfortable with standard
            music notation. If used frequently the students have little to no trouble transferring the
            knowledge they have gained to standard music. If you are teaching a beginning band or orchestra
            you would find the Classroom Size Animal Note/stand note Flash Card Sets in
            the "<Link to="/flash-cards/Classroom-Size-Animal-Note-Standard-Note-Flash-Card-Set-Treble-or-G-Clef">Treble</Link>", "
            <Link
              to="/flash-cards/Classroom-Size-Animal-Note-Standard-Note-Flash-Card-Set-Base-or-F-Clef">Base</Link>"
            and "<Link
              to="/flash-cards/Classroom-Size-Animal-Note-Standard-Note-Flash-Card-Set-Alto-or-C-Clef">Alto
              Clefs</Link>" very helpful as you start your students on their individual instruments. Truly, the
            Grand Staff flash card set will make your job easier and lessen your students’ frustrations.</p>
          <p>To help the students enjoy the music they are learning, the Animal Note method provides two "<Link
            to="/books/The-Fun-Song-Book">Fun Book–One</Link>”, “<Link to="/books/More-Fun-Songs">Fun
            Book–Two</Link>”, and a “<Link to="/books/Christmas-Song-Book">Christmas Song Book</Link>” song books.
            Children truly like working on songs they and others have heard. It encourages them to continue
            striving to get better. Plus these songs make great recital pieces for the program planned for
            parents at the end of the school year.</p>
          <p>The "<Link to="/books/Moving-On-Book-One">Moving On – One</Link>” and “<Link
            to="/books/Moving-On-Book-Two">Moving On – Two</Link>” books are great for the class room
            situation. When the student as successfully completed the above books they are ready to start
            transferring the lessons they have learned to standard music notation. These books provide
            several pieces that are first written in the Animal Notes and then repeated in standard music
            notation. The second book has more difficult songs with varied rhythms and many basic elements
            of early theory that can be practiced and learned while still in the comfort of the Animal Note
            system.</p>
          <p>The
            “<Link to="/books/Theory-Book">Basic Elements of Theory</Link>” book gives simple explanations of
            several basic elements of beginning music theory in a way children can relate too. It is a great
            reference book for the children and perhaps will give you, as the teacher, some alternative
            approaches to teaching some of the basic elements of theory. </p>
          <h3>Testimonial</h3>
          <p><i>"Hi!, I have not yet given you the update on our music class this past<br/>
            school year. Well, we had our end of the year recital and each and every child played a<br/>
            song on the piano at our community building! It was so wonderful, one<br/>
            boy had his memorized and everyone did so well. Many of these kids have<br/>
            never had music lessons in their life. The teachers were very<br/>
            complimentary. One teacher said I am just amazed at what you have done<br/>
            and I can't wait until next year for music. I owe so much to your<br/>
            Noteimals program, it really worked well."</i><br/>
            - Lannie Olsen - Montana</p>
          <p><i>Noteimals method of teaching my piano lab is an all engaging activity that makes learning
            fun! It is brought to us through the loving and exciting spirit of a most delightful lady,
            Glenna Cook</i></p>
          <p><i>This method of reading piano notes excites my piano lab of twenty to twenty four children
            without being boring. I teach third, fourth and fifth graders at Booker Arts Magnet Elementary
            in Little Rock, Arkansas. My fifth graders perform a recital for our Volunteer In Public Schools
            luncheon in May. The Fun Songs for Young Children offers a variety of familiar tunes, allowing
            each child to play a different song. This makes the year’s evaluation much more interesting and
            successful. Thank you, Glenna Cook.</i></p>
          <p>- Mary Lofton - Arkansas</p>
        </div>

        <div className="clear-block">
          <div className="meta">
          </div>

        </div>

      </div>
    </div>
  </Layout>
)
